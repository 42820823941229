.game-protocol-title {
  display: flex;
  flex-direction: row;
  padding: 24px;
  align-items: center;
  gap: 16px;
  align-self: stretch;
  background: var(--colorBgContainer, #FFF);

  /* boxShadow */
  /* box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.02), 0px 1px 6px -1px rgba(0, 0, 0, 0.02), 0px 1px 2px 0px rgba(0, 0, 0, 0.03); */
  justify-content: space-between;
  justify-items: center;
}

.game-protocol-details {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.game-protocol-tournament {
  text-wrap: nowrap;
  display: flex;
  align-items: center;

  overflow: hidden;
  color: var(--colorTextHeading, rgba(0, 0, 0, 0.88));
  text-align: center;
  text-overflow: ellipsis;

  /* SM/SM Normal */
  font-family: "SFProTextRegular";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  /* 166.667% */
}

.game-protocol-details .date {
  overflow: hidden;
  text-align: center;
  text-overflow: ellipsis;
  color: var(--colorTextHeading, rgba(0, 0, 0, 0.88));
  /* SM/SM Normal */
  font-family: "SFProTextRegular";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  /* 166.667% */
}

.game-protocol-details .protocol-score {
  display: flex;
  gap: 8px;
  color: var(--colorInfoText, #002C8C);

  /* Heading/Heading 3 */
  font-family: "Roboto Condensed";
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 32px;
  /* 133.333% */
  letter-spacing: 0.96px;
  text-transform: uppercase;
}

.protocol-score {
  flex: 1;
  display: flex;
  color: var(--colorPrimaryText) !important;
  justify-content: center;
}

.game-protocol-team-name:hover,
.game-protocol-team-name {
  overflow: hidden;
  text-align: center;
  color: var(--colorTextHeading, rgba(0, 0, 0, 0.88));
  text-overflow: ellipsis;
  font-family: "Roboto Condensed";
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  /* 120% */
  letter-spacing: 0.6px;
  text-transform: uppercase;
}

.game-protocol-logo {
  height: 56px;
}

.game-protocol-team {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1 0 0;
}

.game-protocol-lineup-player-wrapper {
  display: flex;
  padding: 12px 16px;
  flex-direction: column;
  /* align-items: center; */
  gap: 8px;
  flex: 1 0 0;
  align-self: stretch;
  border-bottom: 1px solid var(--colorBorderSecondary, rgba(0, 0, 0, 0.06));
  /* background: var(--colorBgContainer, #FFF); */
  align-content: center;
}

.player-number:hover,
.player-number {
  border-radius: 6px;
  background: var(--colorBorderSecondary, rgba(0, 0, 0, 0.06));
  display: flex;
  width: 24px;
  height: 22px;
  justify-content: center;
  align-items: center;

  width: 24px;
  flex-shrink: 0;

  color: var(--colorInfoText, #002C8C);
  text-align: center;
  font-family: "Roboto Condensed";
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
  /* 157.143% */
}

.game-protocol-lineup-title {
  display: flex;
  padding: 24px 24px 8px 24px;
  align-items: center;
  gap: 16px;
  align-self: stretch;
  background: var(--colorBgElevated, #FFF);
  color: var(--colorPrimaryText, #002C8C);
  text-align: center;
  flex: 1;

  /* Heading/Heading 5 */
  font-family: "Roboto Condensed";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  /* 150% */
  letter-spacing: 0.96px;
  text-transform: uppercase;
  justify-content: center;
}

.game-protocol-lineup-container {
  margin: 0 8px;
  display: flex;
  flex-direction: row;
  gap: 16px;
}

.game-protocol-lineup-container>div {
  flex: 1;
}

.game-protocol-lineup-player-wrapper .timing {
  width: 24px;
  color: var(--colorTextTertiary, rgba(0, 0, 0, 0.45));
  text-align: center;

  /* SM/SM Normal */
  font-family: "SFProTextRegular";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  /* 166.667% */
}

.substitution-name {
  color: var(--colorTextTertiary, rgba(0, 0, 0, 0.45));

  /* SM/SM Strong */
  font-family: "SF Pro Text Bold";
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  /* 166.667% */
}

.game-protocol-lineups-wrapper {
  padding: 8px;
  background: var(--colorBgContainer, #FFF);
  border-right: 1px solid var(--colorSplit, rgba(0, 0, 0, 0.06));
}

.game-protocol-lineups {
  height: 100%;
  max-height: 100vh;
  flex: 1;
  width: 174px;
  background: var(--colorBgContainer, #FFF);
  overflow-y: auto;
}

.placement-row-container {
  /* width: 100%; */
  height: 30px;
  padding: 0px 8px 0px 8px;
  gap: 6px;
  flex-shrink: 0;
  align-self: stretch;
  border: 1px solid transparent;
  border-bottom: 1px solid var(--colorSplit, rgba(0, 0, 0, 0.06));
}

.placement-row-container:hover,
.placement-row-container.active {
  border-radius: 6px;
  padding-top: 0;
  border: 1px solid var(--colorPrimaryBorder, #91CAFF);
  background: var(--colorPrimaryBg, #E6F4FF);
}

.placement-row-container:last-of-type {
  border: 1px solid transparent;
}

.time-name {
  color: var(--colorTextDescription, rgba(0, 0, 0, 0.45));
  margin: 8px 8px 0px 8px;
  /* SM/SM Strong */
  font-family: "SFProTextRegular";
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  /* 166.667% */
}

.placement-name {
  overflow: hidden;
  color: var(--colorPrimaryText, #002C8C);
  text-overflow: ellipsis;

  /* SM/SM Strong */
  font-family: "SFProTextRegular";
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  /* 166.667% */
}

.placement-time {
  color: var(--colorTextLabel, rgba(0, 0, 0, 0.65));
  text-align: center;

  /* SM/SM Normal */
  font-family: "SFProTextRegular";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  /* 166.667% */
}

.field-class-protocol {
  position: relative;
  /* border: 1px solid red; */
  background-image: url('/src/assets/img/FieldFooltballBlue.svg');
  width: 848px;
  height: 552px;
  /* height: 100%; */
  /* min-height: 237px; */
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  aspect-ratio: 265/173;
  margin: 0px 24px;
}

.field-class-protocol {
  background-color: var(--fieldProtocolBackground);
}

.title-placement-name {
  color: var(--colorPrimaryText, #002C8C);

  /* Value/LG Strong */
  font-family: "Roboto Condensed";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  /* 150% */
  text-transform: uppercase;
  /* Value/LG Strong */
  font-family: "Roboto Condensed";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  /* 150% */
  text-transform: uppercase;
}

.time-subst,
.time-subst-jersey {
  overflow: hidden;
  color: var(--colorTextDescription, rgba(0, 0, 0, 0.45));
  text-align: center;

  /* SM/SM Normal */
  font-family: "SFProTextRegular";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  /* 166.667% */
}

.time-subst-jersey {
  width: 0px;
  transition: width 0.5s linear;
}

.shirt-number {
  color: var(--black, #000);
  text-align: center;

  /* Value/Base Strong */
  font-family: "Roboto Condensed";
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
  /* 157.143% */
  text-transform: uppercase;
  z-index: 2;
}

.shirt-player-name {
  text-wrap: nowrap;
  border-radius: 6px;
  background: var(--colorBgContainer, #FFF);
  overflow: hidden;
  color: var(--colorTextHeading, rgba(0, 0, 0, 0.88));
  text-overflow: ellipsis;

  /* SM/SM Normal */
  font-family: "SFProTextRegular";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  padding: 0px 6px;
  margin-top: -5px;
  /* 166.667% */
}

.shirt-player-name:hover {
  color: var(--colorTextHeading);
}

.player-shirt {
  max-width: 50px;
}

.player-shirt:hover {
  z-index: 100;
}

.event-icon {}

.jersey-event-container {
  border-radius: 12px;
  border: 1px solid var(--colorPrimaryBorder, #91CAFF);
  background: var(--colorBgContainer, #FFF);
  display: inline-flex;
  height: 20px;
  min-width: 32px;
  padding: 2px 4px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  flex-shrink: 0;
  position: absolute;
  top: -20px;
  z-index: 3;
}

.player-shirt:hover>div>div>div.time-subst-jersey {
  width: 20px;
  color: var(--colorTextDescription);
}

.player-shirt>div>div {
  gap: 0px;
}

.player-shirt:hover>div>div {
  gap: 2px;
}

.subst-player-name,
.subst-player-name:hover {
  color: var(--colorTextDescription, rgba(0, 0, 0, 0.45));
  /* SM/SM Strong */
  font-family: "SFProTextRegular";
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  /* 166.667% */
}