.playlistEditInput {
  display: flex;
  padding: 0px 8px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  gap: 10px;
  flex: 1 0 0;
  border-radius: 6px;
  border: 1px solid var(--new-main-light, #3A78F1);
  background-color: transparent;
  color: var(--white);
  /*font-family: Inter;*/
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  margin-bottom: -8px;
  margin-top: -8px;
  max-width: 94px;
}

.playlistEditInput.error {
  border: 1px solid var(--red);
}

.videoEpisodesContainer {
  /* max-height: 350px; */
  background: var(--colorFillTertiary, rgba(255, 255, 255, 0.08));
  border-radius: 0 0 8px 8px;
}

.videoEpisodesContainer::-webkit-scrollbar-track {
  background-color: transparent;
}

.videoEpisodesContainer::-webkit-scrollbar {
  width: 3px;
  /* Width of the scrollbar */
  border-radius: 13px;
}

.videoEpisodesContainer::-webkit-scrollbar-thumb {
  border-radius: 13px;
  position: absolute;
  left: 5px;
}

.sectionTitle {
  color: var(--table-table-name, #9FAAB8);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  /* 150% */
  padding: 0px 8px 0px 8.5px;
}

.gameBlock {
  color: var(--colorTextDescription, rgba(255, 255, 255, 0.45));
  /* SM/SM Normal */
  font-family: SFProTextRegular;
  font-size: 12px !important;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  /* 166.667% */
  align-self: stretch;
  width: 100%;
  overflow: hidden;
}

.gameBlockTitle {
  color: var(--colorTextLabel, rgba(255, 255, 255, 0.65));
  font-family: "Roboto Condensed";
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  /* 166.667% */
  letter-spacing: 0.84px;
  text-transform: uppercase;
}

.my-episodes-divider {
  color: var(--colorTextDisabled, rgba(255, 255, 255, 0.25));

  /* SM/SM Normal */
  font-family: "SFProTextRegular";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  /* 166.667% */
}

.activeButtonIconHover:hover,
.activeButtonIcon:hover {
  border-radius: 6px;
  background-color: rgba(255, 255, 255, 0.12);
  display: flex;
  align-items: center;
}

.videoEpisodesContainer {
  display: grid;
  grid-template-rows: 0fr;
  transition: 250ms grid-template-rows ease;
}

.videoEpisodesContainer.expanded-container {
  grid-template-rows: 1fr;
}