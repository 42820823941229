.new-event-controller-wrapper {
  display: flex;
  padding: 8px;
  justify-content: space-between;
  align-items: center;

  gap: 8px;
  height: 48px;
  border-radius: 12px;
  background: var(--colorBgLayout, #292D32);
}

.playlists-add-popover-overlay {
  border-radius: 16px;
  background: var(--colorBgLayout, #292D32);
  width: 360px;
  height: calc(100% - 16px - 56px - 140px);
  margin-bottom: 24px;
  /* boxShadowSecondary */
  box-shadow: 0px 6px 16px 0px rgba(0, 0, 0, 0.08), 0px 3px 6px -4px rgba(0, 0, 0, 0.12), 0px 9px 28px 8px rgba(0, 0, 0, 0.05);
}

.playlists-add-popover-overlay>.ant-popover-content {
  height: 100%;
  display: flex;
  overflow: hidden;
}

.playlists-add-popover-overlay>div>.ant-popover-inner {
  padding: 0px;
  flex: 1;
  display: flex;
  flex-direction: column;
}

.playlists-add-popover-overlay>div>.ant-popover-inner>.ant-popover-inner-content {
  flex: 1;
  display: flex;
}

.playlist-qty-value {
  color: var(--colorTextQuaternary, rgba(255, 255, 255, 0.25));
  text-align: right;
  font-family: "Roboto Condensed";
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  /* 166.667% */
  text-transform: uppercase;
}

.add-to-playlist-row {
  height: 40px;
}

.add-to-playlist-row:hover {
  /* display: flex; */
  justify-content: space-between;
  width: calc(113% + 2px) !important;
  cursor: pointer;
}

.single-playlist-add-item:hover::after {
  content: '';
  position: absolute;
  border-radius: 8px;
  background-color: rgba(255, 255, 255, 0.04);
  cursor: pointer;
  margin-left: -16px;
  margin-right: 0px !important;
  padding-left: 0px !important;
  padding-right: 0px !important;
  height: 40px;
  width: 344px;
  z-index: 1;
}

.single-playlist-add-item:hover>.playlist-qty-value {
  display: none;
}

.playlist-qty-plus {
  display: none;
}

.single-playlist-add-item:hover>.playlist-qty-plus {
  display: block;
}

.active-add-to-playlist-button {
  background-color: rgba(255, 255, 255, 0.12);
}

.ant-list-items>.single-playlist-add-item:last-of-type,
.add-to-playlist-row:last-of-type>td {
  border-bottom: none !important;
}

.add-to-playlist-row>td:first-of-type {
  border-bottom-color: transparent !important;
  max-width: 16px !important;
}