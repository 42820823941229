.na-stat {
  background: var(--colorFillAlter, rgba(0, 0, 0, 0.02)) !important;
  color: var(--colorTextDisabled, rgba(0, 0, 0, 0.25)) !important;
  text-align: center !important;
  justify-content: center !important;
  text-transform: lowercase !important;
  /* SM/SM Normal */
  font-family: "SFProTextRegular" !important;
  font-size: 12px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: 20px;
  /* 166.667% */
  border-radius: 8px;
}