:root {
  --bg: #F5F5F7;
  --black: #212121;
  --blue-3: rgba(21, 65, 126, 1);
  --blue-8: rgba(101, 169, 243, 1);
  --blue-9: #002C8C;
  --green-3: rgba(39, 73, 22, 1);
  --green-8: rgba(143, 212, 96, 1);
  --red-3: rgba(88, 24, 28, 1);
  --red-8: rgba(243, 115, 112, 1);
  --white: #FFFFFF;
  --table-table-stroke: #C1CDDE;
  --table-table-name: #9FAAB8;
  --new-main: #3A78F1;
  --red: #F84949;
  --table-table: #f7f8fa;
  --green: #30C321;
  --green-8: var(--green-8, #237804);
  --gray-bg: rgba(43, 49, 56, 0.70);
  --gray-gray: rgba(45, 49, 54, 1);
  font-family: 'Inter', cursive;
  --rt-opacity: 1;
  --gray-light: #3F4752;
  --headerTableColor: #F5F5F5;
  --light-background-color: #3F4752;
  --orange-orange: #EF9F28;
  --new-main-light: #6193F4;
  --gray-light-2: #576170;
  --gray-light2: #576170;
  --Conditional-item-background-hover: rgba(255, 255, 255, 0.08);
  --primary-5: rgba(64, 169, 255, 1);
  --character-disabled-placeholder-25: rgba(0, 0, 0, 0.25);
  --blue-8: rgba(101, 169, 243, 1);
  --colorBgTextHover: rgba(255, 255, 255, 0.12);
  --colorBgContainer: #FFF;
  --Primary-5: #40A9FF;
  --colorFillContent: rgba(255, 255, 255, 0.12);
  --colorFillSecondary: rgba(255, 255, 255, 0.12);
  /* --colorFillTertiary: rgba(255, 255, 255, 0.08); */
  --colorBgElevated: rgba(255, 255, 255, 1);
  --colorBgLayout: rgba(245, 245, 245, 1);
  --colorBgLayoutHeader: rgba(245, 245, 245, 1);
  --colorText: rgba(0, 0, 0, 0.88);
  --headerIconColor: black;
  --headerIconCalendarColor: black;
  --colorFillTertiary: rgba(0, 0, 0, 0.15);
  --colorFillTertiaryHeader: #fff;
  --colorHeaderBorder: rgba(0, 0, 0, 0.06) !important;
  --colorSplit: rgba(0, 0, 0, 0.06) !important;
  --colorBorder: rgba(0, 0, 0, 0.15) !important;
  --colorBorderHeader: rgba(0, 0, 0, 0.06) !important;
  --colorTextPlaceholder: rgba(0, 0, 0, 0.25);
  --colorPrimaryBase: rgba(22, 104, 220, 1);
  --colorInpuText: rgba(0, 0, 0, 0.88);
  --colorTextDescription: rgba(0, 0, 0, 0.45);
  --colorTextTertiary: rgba(0, 0, 0, 0.45);
  --play-circle-filled: rgba(22, 119, 255, 1);
  --colorBgSpotlight: rgba(77, 87, 101, 1);
  --colorPrimaryHover: rgba(60, 137, 232, 1);
  --colorSuccessBase: #49AA19;
  --colorIcon: rgba(255, 255, 255, 0.45);
  --tooltip-75: rgba(0, 0, 0, 0.75);
  --Primary-7: rgba(9, 109, 217, 1);
  --Neutral-1: #FFF;
  --colorFillAlter: rgba(0, 0, 0, 0.02);
  --colorPrimaryActive: (rgba(21, 84, 173, 1));
  --colorFill: rgba(0, 0, 0, 0.15);
  --colorTextHeading: rgba(0, 0, 0, 0.88);
  --colorDotBorder: rgba(255, 255, 255, 1);
  --colorTextLabel: rgba(0, 0, 0, 0.65);
  --colorTextDisabled: rgba(0, 0, 0, 0.25);
  --colorBorderSecondary: rgba(0, 0, 0, 0.06);
  --segmentedGroupBg: rgba(0, 0, 0, 0.04);
  --colorPrimaryText: rgba(0, 44, 140, 1);
  --colorPrimaryBg: rgba(230, 244, 255, 1);
  --colorPrimaryBorder: rgba(145, 202, 255, 1);
  --colorSplit: rgba(0, 0, 0, 0.06);
  --colorInfoText: rgba(0, 44, 140, 1);
  --fieldProtocolBackground: rgba(230, 244, 255, 0.50);
}

[data-theme="dark"] {
  --colorBgLayout: #292D32;
  --colorInfoText: rgba(141, 197, 248, 1);
  --colorBgLayoutTransparent: rgba(41, 45, 50, );
  --colorBgSpotlight: rgba(77, 87, 101, 1);
  --play-circle-filled: rgba(101, 169, 243, 1);
  --colorBgElevated: #3D4249;
  --headerTableColor: rgba(20, 20, 20, 1);
  --headerIconCalendarColor: rgba(255, 255, 255, 0.45);
  --headerIconColor: rgb(255, 255, 255);
  --colorFillTertiary: rgba(255, 255, 255, 0.08);
  --colorFillTertiaryHeader: rgba(255, 255, 255, 0.08);
  --colorBorder: rgba(66, 66, 66, 1);
  --colorBorderHeader: rgba(41, 45, 50, 1) !important;
  --colorHeaderBorder: rgba(66, 66, 66, 1);
  --colorTextPlaceholder: rgba(255, 255, 255, 0.25);
  --colorInpuText: rgba(255, 255, 255, 0.85);
  --colorText: rgba(255, 255, 255, 0.85);
  --colorTextDescription: rgba(255, 255, 255, 0.45);
  --colorTextTertiary: rgba(255, 255, 255, 0.45);
  --colorBgContainer: rgba(20, 20, 20, 1);
  --colorBgLayoutHeader: rgba(20, 20, 20, 1);
  --colorBgContainerDisabled: rgba(255, 255, 255, 0.08);
  --colorPrimaryHover: rgba(60, 137, 232, 1);
  --colorIcon: rgba(255, 255, 255, 0.45);
  --character-secondary-45: rgba(255, 255, 255, 0.45);
  --character-title-85: rgba(255, 255, 255, 0.85);
  --character-primary-inverse: rgba(255, 255, 255, 1);
  --colorTextDisabled: rgba(255, 255, 255, 0.25);
  --colorTextQuaternary: rgba(255, 255, 255, 0.25);
  --colorPrimaryBgHover: rgba(17, 37, 69, 1);
  --colorPrimaryActive: (rgba(21, 84, 173, 1));
  --colorFill: rgba(255, 255, 255, 0.18);
  --colorTextLabel: rgba(255, 255, 255, 0.65);
  --colorTextHeading: rgba(255, 255, 255, 0.85);
  --segmentedGroupBg: rgba(0, 0, 0, 1);
  --colorPrimaryText: rgba(141, 197, 248, 1);
  --colorPrimaryBg: rgba(17, 26, 44, 1);
  --colorPrimaryBorder: rgba(21, 50, 91, 1);
  --colorSplit: rgba(255, 255, 255, 0.06);
  --colorBorderSecondary: rgba(48, 48, 48, 1);
  --fieldProtocolBackground: rgba(26, 33, 45, 1);
}

#root {
  position: fixed;
  height: 100%;
  width: 100%;
}

@font-face {
  font-family: "NotoEmojiFlag";
  unicode-range: U+1F1E6-1F1FF;
  src: url("https://dev.sota.id/files/assets/fonts/NotoColorEmoji-flagsonly.ttf") format("truetype");
}

@font-face {
  font-family: "Inter";
  src: local("Inter"),
    url("../src/assets/fonts/Inter/Inter-VariableFont_slnt,wght.ttf") format("truetype");
}

@font-face {
  font-family: "SF Pro Text Light";
  src: local("SF-Pro-Text-Font-Family"), url("../src/assets/fonts/SF-Pro-Text-Font-Family/SF-Pro-Text-Light.otf");

}

@font-face {
  font-family: SFProTextRegular;
  /* font-weight: 400; */
  font-style: normal;
  src: url("../src/assets/fonts/SF-Pro-Text-Font-Family/SF-Pro-Text-Regular.otf") format("opentype");
}

@font-face {
  font-family: SFProTextSemiBold;
  font-weight: 600;
  font-style: normal;
  src: url("../src/assets/fonts/SF-Pro-Text-Font-Family/SF-Pro-Text-Semibold.otf") format("opentype");
}

@font-face {
  font-family: "SF Pro Text Bold";
  src: local("SF-Pro-Text-Font-Family"), url("../src/assets/fonts/SF-Pro-Text-Font-Family/SF-Pro-Text-Bold.otf");
}

@font-face {
  font-family: "Roboto";
  src: local("SF-Pro-Text-Font-Family"), url("../src/assets/fonts/Roboto/Roboto-Regular.ttf");
}

@font-face {
  font-family: "Roboto Bold";
  font-weight: 500;
  src: local("SF-Pro-Text-Font-Family"), url("../src/assets/fonts/Roboto/Roboto-Bold.ttf");
}

@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@100;200;300;400;500;600;700;800;900&display=swap');

html {
  height: 100%;
}

body {
  background: var(--colorBgContainer, rgba(255, 255, 255, 1));
  height: 100%;
  position: fixed;
}

.App {
  text-align: center;

}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.flex-row {
  display: flex;
  flex-direction: row !important;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.flex-end {
  justify-content: flex-end;
}

.f-ga-0 {
  gap: 0px !important;
}

.f-ga-2 {
  gap: 2px;
}

.f-ga-4 {
  gap: 4px !important;
}

.f-ga-8 {
  gap: 8px !important;
}

.f-ga-6 {
  gap: 6px;
}

.f-ga-12 {
  gap: 12px !important;
}

.f-ga-16 {
  gap: 16px !important;
}

.f-ga-24 {
  gap: 24px;
}

.f-ga-28 {
  gap: 28px;
}

.f-ga-32 {
  gap: 32px !important;
}

.f-ga-40 {
  gap: 40px !important;
}

.f-grow {
  flex-grow: 1;
}

.j-sb {
  justify-content: space-between;
}

.j-sa {
  justify-content: space-around;
}

.j-fs {
  justify-content: flex-start;
}

.j-fe {
  justify-content: flex-end;
}

.ai-c {
  align-items: center;
}

.ai-s {
  align-items: start;
}

.j-ce {
  justify-content: center;
}

.table-color {
  color: var(--table-table-name);
}

.cp {
  cursor: pointer;
}

.blue-color {
  color: #307FF4
}

.disabled {
  color: var(--table-table-name, #9FAAB8) !important;
}

.enabled {
  color: var(--new-main, #3A78F1);
}

.disabledClickable {
  cursor: default;
}

.enabledClickable {
  cursor: pointer;
}

.disabledClickable:hover {
  cursor: not-allowed;
  pointer-events: all !important;
}

.warningColor {
  color: var(--red, #F84949);
}

.pos-rel {
  position: relative;
}

::-webkit-scrollbar,
.rightSidePanel::-webkit-scrollbar {
  width: 4px;
  /* Width of the scrollbar */
  border-radius: 2px;
  opacity: 0;
  transition: opacity 0.5s ease;
}

.rightSidePanel:hover::-webkit-scrollbar,
.rightSidePanel:hover::-webkit-scrollbar {
  opacity: 1;
}

.ant-picker-clear {
  background-color: transparent !important;
}

.ant-picker-status-error {
  background-color: transparent !important;
}

input:disabled,
select:disabled {
  background-color: transparent;
}

.w100 {
  width: 100% !important;
}

.w50 {
  width: 50%;
}

.h100 {
  height: 100% !important;
}

.m10 {
  margin: 10px
}

.mt32 {
  margin-top: 32px;
}

.mt16 {
  margin-top: 16px;
}

.p32 {
  padding: 32px;
}

.pl4 {
  padding-left: 4px;
}

.oy-s {
  overflow-y: auto;
}

.oy-h {
  overflow: hidden;
}

.oy-v {
  overflow: visible !important;
}

.fs-12 {
  font-size: 12px !important;
}

.lh-20 {
  line-height: 20px !important;
}

.modalOverlay {

  position: fixed;
  inset: 0px;
  background-color: var(--gray-bg, rgba(43, 49, 56, 0.70));
  display: flex;
}

input[type="date"]::-webkit-inner-spin-button,
input[type="date"]::-webkit-calendar-picker-indicator {
  display: none;
  -webkit-appearance: none;
}

.g-col-3 {
  grid-column: span 3;
}

.no-wrap {
  white-space: nowrap;
  overflow: hidden;
  text-wrap: nowrap;
}

.no-wrap-visible {
  white-space: nowrap;
  overflow: visible;
  text-wrap: nowrap;
}

.h-avail {
  height: 100%;
  height: -moz-available;
  /* WebKit-based browsers will ignore this. */
  height: -webkit-fill-available;
  /* Mozilla-based browsers will ignore this. */
  height: -webkit-fill-available;
  /* Mozilla-based browsers will ignore this. */
  height: fill-available;
}

.flex-1 {
  flex: 1;
}

.flex-wrap {
  flex-wrap: wrap;
}

.elipsis-text {
  overflow: hidden;
  text-wrap: nowrap;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.input-error-tooltip {
  background: var(--white);
  color: var(--red);
}

.ant-btn {
  display: flex;
  align-items: center;
  justify-content: center;
}

.ant-menu-vertical>.ant-menu-item {
  padding-inline: calc(50% - 10px - 0px) !important;
}

.ant-radio-wrapper>span:not([class]) {
  overflow: hidden;
  color: var(--colorTextSecondary, rgba(255, 255, 255, 0.65));
  text-overflow: ellipsis;

  /* Base/Base Normal */
  font-family: SFProTextRegular;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  /* 157.143% */
}

.baseIconWrapper {
  display: flex;
  min-width: 32px !important;
  height: 32px;
  padding: 4px;
  justify-content: center;
  align-items: center;
  flex: 1;
}

.capitalize::first-letter {
  text-transform: capitalize;
}

.black-text {
  color: black !important;
}

.ml35 {
  margin-left: 35px;
}

.color-description {
  color: var(--colorTextDescription, rgba(255, 255, 255, 0.45));
}

.color-text-label {
  color: var(--colorTextLabel, rgba(0, 0, 0, 0.65));
}

.color-text-heading {
  color: var(--colorTextHeading, rgba(0, 0, 0, 0.88));
}

.roboto-uppercase {
  font-family: "Roboto Condensed";
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  /* 166.667% */
  letter-spacing: 0.84px;
  text-transform: uppercase;
}

.no-select {
  -webkit-user-select: none;
  /* Safari */
  -ms-user-select: none;
  /* IE 10 and IE 11 */
  user-select: none;
  /* Standard syntax */
}

.underline:hover {
  text-decoration: underline;
}