.period-name {
  display: flex;
  width: 16px;
  height: 16px;
  justify-content: center;
  /* align-items: center; */
  border-radius: 3px;
  background: rgba(255, 255, 255, 0.06);
  line-height: 17px;
  padding-bottom: 4px;
}

.period-name.light {
  background: var(--colorFillContent, rgba(255, 255, 255, 0.12));
}